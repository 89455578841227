import { Text, Flex } from 'packages/uikit'
import styled from 'styled-components'

export const Header = styled.div`
  max-width: 1388px;
  width: 100%;
  margin: auto;
  margin-bottom: 26px;
`

export const Title = styled(Text).attrs({ as: 'h2', textAlign: 'center', fontSize: ['45px', , , '85px'] })`
  font-family: 'Righteous';
  width: fit-content;
  margin: auto;

  color: white;
  position: relative;
  z-index: 1;

  text-shadow: 0px -3px 3px rgba(0, 0, 0, 1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  /* &:before,
  &:after {
    content: attr(title);
    color: rgba(255, 255, 255, 1);
    position: absolute;
    z-index: 0;
  }

  &:before {
    top: 5px;
    left: 1px;
  }

  &:after {
    top: 3px;
    left: 0px;
  } */
`

export const Description = styled(Text).attrs({
  as: 'h3',
  textAlign: 'center',
  fontSize: ['20px', , , '35px'],
  color: 'black',
})`
  font-family: 'Alfa Slab One';
`

export const ButtonWrapper = styled(Flex).attrs({ justifyContent: 'center', alignItems: 'center' })`
  gap: 8px;
  margin-bottom: 42px;
`

export const Info = styled(Flex).attrs({ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' })`
  margin: auto;
`

export const ClipPatchWrapper = styled.div`
  --padding: 2px;

  clip-path: polygon(7% 0, 100% 0, 100% 75%, 93% 100%, 0 100%, 0 25%);
  display: flex;

  max-width: 378px;
  width: 100%;
  min-height: 115px;

  background: linear-gradient(90deg, #00f27b 0%, #154f32 100%);
  padding: var(--padding);
  position: relative;
  margin-bottom: 36px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 86px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc(100% - calc(var(--padding) * 2));
    height: calc(100% - calc(var(--padding) * 2));

    clip-path: polygon(7% 0, 100% 0, 100% 75%, 93% 100%, 0 100%, 0 25%);
    background: linear-gradient(
      180deg,
      rgba(161, 161, 161, 0.8) 0%,
      rgba(19, 19, 19, 0.8) 56.5%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  max-width: 1094px;
  padding: 0 16px;
`
