import { Container } from './styles'
import HeaderSection from './components/Header'
import FeaturesSection from './components/Features'
import WhyChooseSection from './components/WhyChoose'
import FooterSection from './components/Footer'

const LandingPage = () => {
  return (
    <>
      <Container>
        <HeaderSection />
        <FeaturesSection />
        <WhyChooseSection />
        <FooterSection />
      </Container>
    </>
  )
}

export default LandingPage
