import { Box, Text } from 'packages/uikit'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 1746px;
  padding: 0 12px;
  width: 100%;
  margin: auto;
`

export const BoxInfo = styled(Box).attrs({ padding: '15px 20px' })`
  --radius: 20px;

  background: linear-gradient(180deg, #666666 0%, #000000 100%);
  border: 1px solid #ffffff;
  border-radius: var(--radius);

  position: relative;
  box-shadow: 0px 5px 0px 0px #ffffff;
`

export const TitleSection = styled(Text).attrs({ fontSize: ['32px', , , '46px'] })`
  font-family: 'Alfa Slab One';
  text-shadow: 0px 5px 4px #0000004d;
`

export const FooterBoxInfo = styled(BoxInfo).attrs({ padding: ['10px 20px'] })`
  border-radius: calc(var(--radius) / 2);
  box-shadow: 0px 3px 0px 0px #ffffff;
`

export const TextGradient = styled(Text).attrs({ fontWeight: '700' })`
  /* background: linear-gradient(180deg, #052d18 0%, #10934e 100%); */
  background: linear-gradient(180deg, #666666 0%, #000000 100%);
  -webkit-background-clip: text;
  color: transparent;
`
