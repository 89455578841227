import { Text, Box, Flex } from 'packages/uikit'
import { TitleSection, TextGradient } from 'views/Landing/styles'
import { Fragment } from 'react'
import { BgBox, PrimaryBox, TabBox, TabPosition, TabWrapper, DexWrapper } from './styles'

const FEATURES = [
  {
    logo: '/images/landing/swap-feature.svg',
    title: 'Swap',
    description: 'Execute trades with lightning speed and precision.',
  },
  {
    logo: '/images/landing/liquidity-feature.svg',
    title: 'Liquidity',
    description: 'Adding liquidity to various pools, Contribute and Earn Trading fees.',
  },
  {
    logo: '/images/landing/stake-feature.svg',
    title: 'Stake',
    description: 'Secure your assets while earning passive income through staking with competitive rewards.',
  },
  {
    logo: '/images/landing/farms-feature.svg',
    title: 'Farms',
    description: 'Offering a variety of farming pools tailored with high return - low risk - healthy investment.',
  },
]

const FeaturesSection = () => {
  return (
    <>
      <Box pt="36px">
        <Flex justifyContent="center" mb={['0', , , , '36px']}>
          <TitleSection mr="12px">HybridEx</TitleSection>
          <TitleSection color="#282828">Features</TitleSection>
        </Flex>

        <Box mb="16px">
          <BgBox bg="/images/landing/features-bg.svg" className="main">
            <TabPosition>
              <TabBox>
                <TabWrapper>
                  <TextGradient fontSize={['30px', , , '40px']}>DEX</TextGradient>
                </TabWrapper>
              </TabBox>
            </TabPosition>

            <PrimaryBox>
              <DexWrapper padding={['0 20px', , , , '40px 40px 20px 40px']}>
                {FEATURES.map((item) => (
                  <Fragment key={item.title}>
                    <FeatureItem img={item.logo} title={item.title} description={item.description} />
                  </Fragment>
                ))}
              </DexWrapper>
            </PrimaryBox>
          </BgBox>
        </Box>

        <Flex style={{ gap: '36px' }} flexDirection={['column', , , , , 'row']} pt="36px" pb="56px">
          <BgBox width="100%" bg="/images/landing/feature-launchpad-bg.svg" className="launchpad">
            <TabPosition>
              <TabBox width="fit-content">
                <TabWrapper>
                  <TextGradient fontSize={['30px', , , '40px']}>Launchpad</TextGradient>
                </TabWrapper>
              </TabBox>
            </TabPosition>

            <PrimaryBox>
              <Flex
                flexDirection={['column-reverse', , , , 'row']}
                alignItems={['flex-start', , , , 'center']}
                justifyContent="space-between"
                padding={['0 20px', , , , '40px']}
                height="calc(100% - 56px)"
              >
                <Text color="var(--black-color)" maxWidth="526px">
                  Kickstart your blockchain projects with HybridPad.
                  <br /> Gain early access to essential tools, funding, and community support to to vigorously promote
                  Start-up projects on Hybrid network.
                </Text>

                <TextGradient
                  fontSize={['25px', , , '35px']}
                  display={['block', , , , 'none']}
                  fontWeight="700"
                  color="#282828"
                >
                  Launchpad
                </TextGradient>
                <Flex width={['35', , '45px', , '70px']} flexShrink="0" pb={['10px', , , , '0']}>
                  <img src="/images/landing/launchpad-feature.svg" alt="" />
                </Flex>
              </Flex>
            </PrimaryBox>
          </BgBox>

          <BgBox width="100%" bg="/images/landing/feature-nft-bg.svg" className="nft">
            <TabPosition>
              <TabBox width="fit-content">
                <TabWrapper>
                  <TextGradient fontSize={['30px', , , '40px']}>NFT</TextGradient>
                </TabWrapper>
              </TabBox>
            </TabPosition>

            <PrimaryBox>
              <Flex
                flexDirection={['column-reverse', , , , 'row']}
                alignItems={['flex-start', , , , 'center']}
                justifyContent="space-between"
                padding={['0 20px 40px', , , , '40px']}
                height="calc(100% - 56px)"
              >
                <Text color="var(--black-color)" maxWidth="587px">
                  Step into the world of digital collectibles with HybridArt. <br /> Create, trade, and showcase unique
                  NFTs within a decentralized marketplace that values security and authenticity.
                </Text>

                <TextGradient
                  fontSize={['25px', , , '35px']}
                  display={['block', , , , 'none']}
                  fontWeight="700"
                  color="#282828"
                >
                  NFT
                </TextGradient>

                <Flex width={['35', , '45px', , '70px']} flexShrink="0" pb={['10px', , , , '0']}>
                  <img src="/images/landing/nft-feature.svg" alt="" />
                </Flex>
              </Flex>
            </PrimaryBox>
          </BgBox>
        </Flex>
      </Box>
    </>
  )
}

export default FeaturesSection

const FeatureItem = ({ img, title, description }) => {
  return (
    <>
      <Flex flexDirection="column" padding={['10px 0', , , , '10px']} maxWidth={['100%', , , , '363px']}>
        <Box width={['35', , '45px', , '70px']} pb="10px">
          <img src={img ?? ''} alt="" />
        </Box>

        <TextGradient fontSize={['25px', , , '35px']} fontWeight="700" color="#282828">
          {title}
        </TextGradient>

        <Text color="#282828">{description}</Text>
      </Flex>
    </>
  )
}
