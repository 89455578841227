import { Box, Text } from 'packages/uikit'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
  position: relative;
  background: none;
  background-size: 100% 100%;

  width: 100%;
  height: auto;
  padding: 0 20px;

  ${({ theme }) => theme.mediaQueries.lg} {
    aspect-ratio: calc(1725 / 701);
    background: url('/images/landing/whychoose-bg.svg');
    background-size: contain;
    padding: 50px 0;
  }

  /* &:after {
    content: '';
    position: absolute;

    bottom: 3px;
    right: 8px;
    transform-origin: bottom-right;

    background: url('/images/landing/flip.svg');
    width: 100%;
    max-width: 97.39px;
    aspect-ratio: calc(97.39 / 91.56);
    height: auto;
  } */
`

export const GridList = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  height: 100%;
  max-width: 1463px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(2, 1fr);
  }

  & > .why-choose--item {
    &:nth-child(1) {
      max-width: 487px;
      /* & > .box-icon {
        width: 66px;
      } */
    }

    &:nth-child(2) {
      max-width: 646px;
      /* & > .box-icon {
        width: 66px;
      } */
    }

    &:nth-child(3) {
      max-width: 597px;
      /* & > .box-icon {
        width: 66px;
      } */
    }

    &:nth-child(4) {
      max-width: 614px;
      /* & > .box-icon {
        width: 66px;
      } */
    }
  }
`
