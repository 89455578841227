import styled from 'styled-components'

export const RobotPosition = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 90px;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 250px;
  }
`
