/* eslint-disable jsx-a11y/anchor-is-valid */
import { TitleSection, FooterBoxInfo } from 'views/Landing/styles'
import { Text, Box, Flex, Link } from 'packages/uikit'
import { FlexGap } from 'components/Layout/Flex'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { RobotPosition } from './styles'

const FooterSection = () => {
  return (
    <>
      <Box py="36px">
        <Flex justifyContent="center" mb="26px">
          <TitleSection color="var(--black-color)">Join us</TitleSection>
        </Flex>

        <FlexGap justifyContent="center" flexWrap="wrap" gap="16px" mb="36px">
          <FooterBoxInfo as={NextLinkFromReactRouter} to="https://x.com/Hybrid_Ex" target="_blank" minHeight="50px">
            <FlexGap gap="6px" alignItems="center" height="100%">
              <Flex>
                <img src="/images/landing/social-x-icon.svg" alt="social-icon" />
              </Flex>
              <Text fontSize={['12px', , , , '18px']}>Follow Twitter</Text>
            </FlexGap>
          </FooterBoxInfo>

          <FooterBoxInfo as={NextLinkFromReactRouter} to="https://t.me/Hybrid_Ex" target="_blank" minHeight="50px">
            <FlexGap gap="6px" alignItems="center" height="100%">
              <Flex>
                <img src="/images/landing/social-tele-icon.svg" alt="social-icon" />
              </Flex>
              <Text fontSize={['12px', , , , '18px']}>Join Telegram</Text>
            </FlexGap>
          </FooterBoxInfo>

          <FooterBoxInfo as={NextLinkFromReactRouter} to="mailto:support@hybridex.xyz" minHeight="50px">
            <FlexGap gap="6px" alignItems="center" height="100%">
              <Flex>
                <img src="/images/landing/social-contact-icon.svg" alt="social-icon" />
              </Flex>
              <Text fontSize={['12px', , , , '18px']}>Contact Us</Text>
            </FlexGap>
          </FooterBoxInfo>
        </FlexGap>

        <FlexGap justifyContent="center" gap="16px" mb="16px">
          <Link href="#" external>
            <Text color="black">Blog</Text>
          </Link>
          <Link href="#" external>
            <Text color="black">Docs</Text>
          </Link>
        </FlexGap>

        <FlexGap justifyContent="center" gap="16px" position="relative">
          <Flex flexDirection="column">
            <Flex alignItems="center" justifyContent="center" mb="10px">
              <img src="/images/footer-logo.svg" alt="footer-logo" />

              <Text fontFamily="Righteous" fontSize={['25px', , , , '35px']} color="black" ml="8px">
                HybridEx
              </Text>
            </Flex>

            <Text fontSize={14} color="black">
              © 2024 HybridEx. All rights reserved.
            </Text>
          </Flex>

          <RobotPosition>
            <img src="/images/robot.svg" alt="robot" />
          </RobotPosition>
        </FlexGap>
      </Box>
    </>
  )
}

export default FooterSection
