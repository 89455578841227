import { Text, Box, Flex } from 'packages/uikit'
import { TitleSection, TextGradient } from 'views/Landing/styles'
import { Fragment } from 'react'
import { Wrapper, GridList } from './styles'

const WHYCHOOSE_ITEMS = [
  {
    logo: '/images/landing/ai-whychoose.svg',
    title: 'AI-Powered Trading',
    description: 'Leverage AI-driven insights for optimal trading decisions and market analysis.',
  },
  {
    logo: '/images/landing/auto-whychoose.svg',
    title: 'Automated Smart Contracts',
    description:
      'Experience secure and trustless transactions with automated smart contracts, reducing the need for intermediaries.',
  },
  {
    logo: '/images/landing/security-whychoose.svg',
    title: 'Top-Notch Security',
    description:
      'Built on the Hybrid Blockchain, HybridEx employs industry-leading security protocols to protect your assets and data.',
  },
  {
    logo: '/images/landing/user-whychoose.svg',
    title: 'User-Centered Design',
    description: 'Enjoy a seamless trading experience with an intuitive interface designed optimal for traders.',
  },
]

const WhyChooseSection = () => {
  return (
    <>
      <Box py="36px">
        <Flex justifyContent="center" alginItems="center" flexDirection={['column', , , , 'row']} mb="36px">
          <TitleSection color="var(--black-color)" mr="12px" textAlign="center">
            Why choose
          </TitleSection>
          <TitleSection textAlign="center">HybridEx</TitleSection>
        </Flex>

        <Wrapper>
          <GridList>
            {WHYCHOOSE_ITEMS.map((item) => (
              <Fragment key={item.title}>
                <Item img={item.logo} title={item.title} description={item.description} />
              </Fragment>
            ))}
          </GridList>
        </Wrapper>
      </Box>
    </>
  )
}

export default WhyChooseSection

const Item = ({ img, title, description }) => {
  return (
    <>
      <Flex flexDirection="column" padding="40px 0 0" className="why-choose--item">
        <Box mb="16px" width={['35', , '45px', , '70px']} className="box-icon">
          <img src={img ?? ''} alt="" />
        </Box>

        <TextGradient fontSize={['25px', , , , '40px']}>{title}</TextGradient>

        <Text color="#282828">{description}</Text>
      </Flex>
    </>
  )
}
