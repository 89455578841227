import { Box, Flex } from 'packages/uikit'
import styled from 'styled-components'

export const BgBox = styled(Box)<{ bg?: string }>`
  --radius: 20px;
  --space-top: 56px;

  border-radius: var(--radius);
  border-top-left-radius: 0px;

  position: relative;
  padding: 1px;
  /* background: #38f096;
  transform: translateY(var(--space-top)); */

  background: ${({ bg }) => `url(${bg}) no-repeat`};

  &.main {
    background: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      aspect-ratio: calc(1725 / 376);
      background: ${({ bg }) => `url(${bg}) no-repeat`};
      background-size: contain;
    }
  }

  &.launchpad {
    background: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      aspect-ratio: calc(835 / 279);
      background: ${({ bg }) => `url(${bg}) no-repeat`};
      background-size: contain;
    }
  }

  &.nft {
    background: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      aspect-ratio: calc(836 / 280);
      background: ${({ bg }) => `url(${bg}) no-repeat`};
      background-size: contain;
    }
  }
`

export const PrimaryBox = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transform: translateY(var(--space-top));
`

export const TabBox = styled(BgBox)`
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 0;
`

export const TabWrapper = styled(Box)`
  padding: 10px 40px;
  border-radius: inherit;
`

export const TabPosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
  /* transform: translateY(calc(-100% - calc(var(--space-top) - 1px))); */
`

export const DexWrapper = styled(Flex)`
  flex-flow: wrap;
  justify-content: space-between;
`
