import { Text, ArrowForwardIcon, Button, Flex, Box } from 'packages/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { BoxInfo } from 'views/Landing/styles'
import { Title, Description, Header, ButtonWrapper, ClipPatchWrapper, Info, GridLayout } from './styles'

const HeaderSection = () => {
  return (
    <>
      <Box py="36px">
        <Header>
          <Title title="HybridEx">HybridEx</Title>
          <Description mb="12px">
            The State-of-the-Art of Decentralized Trading <br /> merging the power of Hybrid blockchain with AI-driven
            insights
          </Description>
          <Text as="p" textAlign="center" color="black" fontSize={['16px', , , '22px']} lineHeight="2">
            A secure, efficient, and intelligent DeFi experience
          </Text>
        </Header>

        <ButtonWrapper>
          <Button
            variant="link"
            endIcon={<ArrowForwardIcon width={22} ml="12px" color="transparent" />}
            maxWidth="257px"
            width="100%"
            as={NextLinkFromReactRouter}
            to="/swap"
          >
            <Text fontSize={['14px', , , '20px']} fontWeight="600" color="black" noWrap>
              Start Trade
            </Text>
          </Button>
          <Button
            variant="link"
            endIcon={<ArrowForwardIcon width={22} ml="12px" color="transparent" />}
            maxWidth="257px"
            width="100%"
            as={NextLinkFromReactRouter}
            to="https://hybrid-testnet.bridge.caldera.xyz/"
            target="_blank"
          >
            <Text fontSize={['14px', , , '20px']} fontWeight="600" color="black" noWrap>
              Bridge
            </Text>
          </Button>
        </ButtonWrapper>

        <Info>
          <ClipPatchWrapper>
            <Flex width="100%" flexDirection="column">
              <Flex
                justifyContent="center"
                alignItems="center"
                height="50%"
                borderBottom="1px solid rgba(255, 255, 255, 0.5)"
              >
                <Text fontSize="20px" fontWeight="700">
                  BUILD ON
                </Text>
                <Box ml="8px">
                  <img src="/images/landing-hybrid-logo.svg" alt="" />
                </Box>
              </Flex>

              <Flex justifyContent="center" alignItems="center" height="50%">
                <Text fontSize="16px" fontWeight="200">
                  The trading suite from native asset
                </Text>
              </Flex>
            </Flex>
          </ClipPatchWrapper>

          <GridLayout>
            <BoxInfo>
              <Flex flexDirection="column">
                <Text fontWeight="200">Total HYX Supply</Text>
                <Text fontSize={['25px', , , , '35px']} fontWeight="700">
                  $100.00M
                </Text>
              </Flex>
            </BoxInfo>

            <BoxInfo>
              <Flex flexDirection="column">
                <Text fontWeight="200">Total TxH</Text>
                <Text fontSize={['25px', , , , '35px']} fontWeight="700">
                  100,000,000
                </Text>
              </Flex>
            </BoxInfo>

            <BoxInfo>
              <Flex flexDirection="column">
                <Text fontWeight="200">Total Volume</Text>
                <Text fontSize={['25px', , , , '35px']} fontWeight="700">
                  $1,000,000
                </Text>
              </Flex>
            </BoxInfo>
          </GridLayout>
        </Info>
      </Box>
    </>
  )
}

export default HeaderSection
